<template>
  <div class="notfound-container">
    <img src="/static/img/404-page02.png" alt="" />
    <h2>Page Not Found</h2>
    <div class="hero-action">
      <router-link class="mybtn-action" :to="'/'">Home Page</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  methods: {},
};
</script>

<style lang="scss">
.notfound-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h2 {
    margin: 2.5rem 0rem 2.3rem 0rem;
    font-size: 2.3rem;
    font-weight: 400;
  }
}

@media screen and (max-width: 600px) {
  .notfound-container {
    h2 {
      font-size: 24px;
    }
    img {
      max-width: 90%;
    }
  }
}
</style>

<template>
  <div class="about-container" id="about" data-aos="fade-up">
    <div class="about-img--box">
      <img class="about-img" src="@/assets/img/mypic.png" alt="" />
    </div>

    <div class="about-text--box">
      <h2>About Me</h2>
      <p>
        Hello! I'm Uthman. A Frontend developer based in Istanbul, Turkey. I
        developed interest in web development back in 2015 when I was a first
        year college student. Ever since my interest in web development
        continues to grow.
      </p>
      <p>
        Graduating from Udacity Frontend Nanodegree was my second move to
        deepening my web development skills to a professional level.
      </p>
      <p>
        I came to realized that translating design to live-website is what
        brings out the creativity and the passion in me, hence the quest to
        learn more and build more things for the web.
      </p>
      <p>
        I've had the privilege of working in the college software development
        team, a start-up and IT teaching center. My focus now is on building
        media content related web applications.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";
.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 6rem;
  margin-bottom: 4.5rem;
}

.about-img--box {
  width: 330px;
  height: 360px;
  position: relative;
  margin: 10px auto;
}

.about-img--box::before,
.about-img--box::after {
  content: "";
  position: absolute;
}

.about-img--box::before {
  width: 80%;
  height: 80%;
  border-radius: 3px;
  top: -30px;
  left: -30px;
  background: var(--tertiary-bg-navy);
  z-index: 1;
  transition: $transition;
}

.about-img--box::after {
  width: 20%;
  height: 20%;
  z-index: 1;
  bottom: -16px;
  right: -16px;
  border-right: 4px solid var(--main-green-color);
  border-bottom: 4px solid var(--main-green-color);
  transition: $transition;
}

.about-img {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-img--box:hover:before {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  top: -2px;
  left: -2px;
}

.about-img--box:hover::after {
  width: 100%;
  height: 100%;
  z-index: 1;
  bottom: -3px;
  right: -3px;
}

.about-text--box {
  width: 100%;
  padding: 10px 20px;
}

.about-text--box h2 {
  margin: 0px 0px 32px 0px;
  color: var(--main-dark_light-color);
  position: relative;
}

.about-text--box h2::after {
  content: "";
  position: absolute;
  top: 18px;
  width: 100px;
  height: 1px;
  margin-left: 20px;
  background: var(--tertiary-bg-navy);
}
@media screen and (max-width: 390px) {
  .about-img--box {
    width: 90%;
    height: 286px;
  }
}

@media screen and (min-width: 991px) {
  .about-text--box h2::after {
    width: 300px;
  }

  .about-container {
    flex-direction: row;
  }
  .about-text--box {
    width: calc(100% - 400px);
    padding: 10px 20px 10px 100px;
  }
}
</style>

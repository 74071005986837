<template>
  <div class="contact-wrapper">
    <span class="left-angle"></span>
    <div class="getin-touch">
      <h2 data-aos="fade-up">Let's Get In Touch!</h2>
      <p data-aos="fade-up">
        Interested in working together, feel free to send a message, my inbox is
        always open. I'll try my best to get back to you!
      </p>
      <a
        class="mybtn-email"
        href="mailto:uthmana.muhammad@gmail.com"
        rel="noopener noreferrer"
        target="_blank"
        data-aos="fade-zoom-in"
        >Email Me
      </a>

      <div class="getin-touch-social--container">
        <div class="getin-touch--email gitsw1">
          <div class="email__itemLinkWrapper">
            <a href="mailto:uthmana.muhammad@gmail.com"
              >uthmana.muhammad@gmail.com</a
            >
          </div>
        </div>

        <div class="getin-touch--socialmedia gitsw0">
          <ul class="">
            <li>
              <a
                href="https://github.com/uthmana"
                aria-label="GitHub"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-github"
                >
                  <title>GitHub</title>
                  <path
                    d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"
                  ></path></svg
              ></a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/uthman.muhammad/"
                aria-label="Instagram"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-instagram"
                >
                  <title>Instagram</title>
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                  <path
                    d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"
                  ></path>
                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg
              ></a>
            </li>
            <li>
              <a
                href="https://twitter.com/Uthman63693862"
                aria-label="Twitter"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-twitter"
                >
                  <title>Twitter</title>
                  <path
                    d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"
                  ></path></svg
              ></a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/uthman-ahmad/"
                aria-label="Linkedin"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-linkedin"
                >
                  <title>LinkedIn</title>
                  <path
                    d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"
                  ></path>
                  <rect x="2" y="9" width="4" height="12"></rect>
                  <circle cx="4" cy="4" r="2"></circle></svg
              ></a>
            </li>
            <li>
              <a
                href="https://codepen.io/tk_1"
                aria-label="Codepen"
                target="_blank"
                rel="noreferrer"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-codepen"
                >
                  <title>CodePen</title>
                  <polygon
                    points="12 2 22 8.5 22 15.5 12 22 2 15.5 2 8.5 12 2"
                  ></polygon>
                  <line x1="12" y1="22" x2="12" y2="15.5"></line>
                  <polyline points="22 8.5 12 15.5 2 8.5"></polyline>
                  <polyline points="2 15.5 12 8.5 22 15.5"></polyline>
                  <line x1="12" y1="2" x2="12" y2="8.5"></line></svg
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <span class="right-angle"></span>
  </div>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";

.contact-wrapper {
  width: 90%;
  margin: 0px auto 90px auto;
  padding-top: 3rem;
  position: relative;
}

.contact-wrapper::before,
.contact-wrapper::after {
  content: "";
  width: 80px;
  height: 80px;
  border: solid 0.15rem;
  position: absolute;
}

.contact-wrapper::before {
  border-top-color: var(--main-green-color);
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: var(--main-green-color);
}

.contact-wrapper::after {
  border-top-color: transparent;
  border-right-color: var(--main-green-color);
  border-bottom-color: var(--main-green-color);
  border-left-color: transparent;
  right: 0;
  bottom: -60px;
}

.getin-touch {
  text-align: center;
  width: 100%;
  margin: 0px auto 4.5rem auto;
  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--tertiary-font-color);
  }
  p {
    font-size: $fz-md;
    padding: $fz-md;
    margin-bottom: 2rem;
  }
}

.getin-touch-social--container {
  width: 100%;
}

.getin-touch--socialmedia {
  width: 100%;
  display: flex;
  justify-content: center;
}

.getin-touch--socialmedia.gitsw0 ul {
  width: auto;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.getin-touch--socialmedia.gitsw0 li a {
  padding: 12px;
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: $transition;
}

.getin-touch--socialmedia.gitsw0 li a:hover,
.getin-touch--socialmedia.gitsw0 li a:focus {
  transform: translateY(-3px);
}

.getin-touch--socialmedia.gitsw0 li a:hover,
.getin-touch--socialmedia.gitsw0 li a:focus,
.email__itemLinkWrapper a:hover {
  color: var(--main-green-color);
}

.gitsw0 li a svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.email__itemLinkWrapper a {
  margin: 20px auto;
  padding: 10px;
  font-family: $font-mono;
  font-size: $fz-md;
  line-height: $fz-xl;
  letter-spacing: 0.1rem;
  color: inherit;
  text-decoration: none;
  transition: $transition;
}

.mybtn-email {
  padding: 0.8rem 0.8rem;
  border-radius: 5px;
  border: 2px solid var(--main-green-color);
  background: transparent;
  outline: none;
  color: var(--primary-font-color);
  font-weight: bold;
  font-size: $fz-md;
  font-family: $font-mono;
  cursor: pointer;
  transition: color 0.5s;
  width: 100%;
  text-decoration: none;
  display: block;
  width: 140px;
  margin: 20px auto;
  transition: $transition;
}

.mybtn-email:hover {
  color: var(--main-green-color);
}

@media screen and (min-width: 991px) {
  .contact-wrapper {
    width: 100%;
  }

  .getin-touch {
    text-align: center;
    width: 460px;
    height: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      font-size: 3rem;
      color: var(--tertiary-font-color);
    }
    p {
      font-size: $fz-md;
      margin-bottom: 2.2rem;
    }
  }

  .getin-touch--socialmedia {
    position: fixed;
    top: 50%;
    right: 45px;
    transform: translate(0, -50%);
    z-index: 10;
    width: 45px;
  }

  .getin-touch--socialmedia.gitsw0 ul {
    flex-direction: column;
  }

  .getin-touch--email {
    position: fixed;
    left: 45px;
    bottom: 70px;
  }

  .email__itemLinkWrapper a {
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
  }
  .email__itemLinkWrapper:after {
    content: "";
    width: 100px;
    height: 2px;
    background: var(--secondary-font-color);
    position: absolute;
    left: -30px;
    bottom: -24px;
    transform: rotate(90deg);
  }
}
</style>

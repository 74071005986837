<template>
  <div class="home" id="home">
    <section class="heropage container" id="#home">
      <Heropage />
    </section>

    <section class="about container" id="about">
      <About />
      <Skills />
    </section>

    <section class="featured container" id="projects">
      <div class="featured-content--wrapper">
        <h2 class="featured-title">Featured Projects</h2>
        <Featured
          v-for="project in _allprogects.featured"
          :key="project.id"
          :ProjectData="project"
        >
        </Featured>
      </div>

      <h2 class="noteworthy-title">Noteworthy Projects</h2>
      <div class="noteworthy-projects">
        <!-- <Noteworthy
          v-for="project in _allprogects.noteworthy"
          :key="project.id"
          :ProjectData="project"
        >
        </Noteworthy> -->

        <Noteworthy
          v-for="project in noteworthydata"
          :key="project.id"
          :ProjectData="project"
        >
        </Noteworthy>

       
      </div>
       <button class="mybtn-action d-block" v-if="_allprogects.noteworthy.length > 5" ref="btnMoreLess" v-on:click="getMoreProjects">Show More</button>
    </section>

    <section class="contact container" id="contact">
      <Contact />
    </section>

    <Preloader :show="isLoad"> </Preloader>
  </div>
</template>

<script>
// @ is an alias to /src

import Heropage from "@/components/Heropage.vue";
import About from "@/components/About.vue";
import Skills from "@/components/Skills.vue";
import Featured from "@/components/Featured.vue";
import Noteworthy from "@/components/Noteworthy.vue";
import Contact from "@/components/Contact.vue";
import Preloader from "@/components/Preloader.vue";

export default {
  name: "Home",
  components: {
    Heropage,
    About,
    Skills,
    Featured,
    Noteworthy,
    Contact,
    Preloader,
  },
  data() {
    return {
      allProject: {},
      isLoad: false,
      isShowMore: false,
      noteworthydata : []
    };
  },
  created() {
    this.isLoad = false;
    this.$store.dispatch("getAllProject");
  },
  watch: {
    "$store.state._data": function (newValue) {
      if (newValue) {
        this.noteworthydata = (newValue.noteworthy).slice(0, 6);
        setTimeout(() => {
          this.isLoad = true;
        }, 800);

      }
    },
  },
  mounted() {},
  methods:{
   getMoreProjects(){
        this.isShowMore = !this.isShowMore;
        if(!this.isShowMore){
         this.noteworthydata = (this._noteworthy).slice(0, 6);
         this.$refs.btnMoreLess.textContent = "Show More";
        }else{
         this.noteworthydata = this._noteworthy;
        this.$refs.btnMoreLess.textContent = "Show Less";
        }
   }

  },
  computed: {
    _allprogects() {
      return this.$store.state._data;
    },
    _featured() {
      return this.$store.state._data.featured;
    },
    _noteworthy() {
      return this.$store.state._data.noteworthy;
    },
  },
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";

.about {
  margin: 0px auto;
  margin-bottom: 5rem;
}

.featured {
  padding-top: 4rem;
  background: var();
}

.d-block{
  display: block;
}
.noteworthy-projects {
  display: flex;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.featured-content--wrapper {
  margin-bottom: 8rem;
}
.noteworthy-projects h2 {
  width: 100%;
  color: var(--main-dark_light-color);
}

.featured-title {
  color: var(--main-dark_light-color);
  position: relative;
  margin-bottom: 3rem;
  &::after {
    content: "";
    position: absolute;
    top: 18px;
    width: 300px;
    height: 1px;
    margin-left: 20px;
    background: var(--tertiary-bg-navy);
  }
}

.noteworthy-title {
  color: var(--main-dark_light-color);
  text-align: center;
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .featured-title {
    &::after {
      content: "";
      position: absolute;
      top: 18px;
      width: 100px;
    }
  }

  .noteworthy-projects {
    justify-content: center;
  }

  .featured-content--wrapper {
    margin-bottom: 4rem;
  }
  .featured-title {
    margin-left: 20px;
  }
}



</style>

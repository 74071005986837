import Vue from "vue";
import Vuex from "vuex";
import _projects from "../assets/data.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    _data: {},
  },
  mutations: {
    GET_All_PROJECTS(state, project) {
      state._data = project;
    },
  },
  actions: {
    getAllProject({ commit }) {
      commit("GET_All_PROJECTS", _projects);
    },
  },
});

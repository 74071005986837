<template>
  <div class="page-preloader" :class="{ hideloader: show }">
    <img class="img-light" src="/static/img/logo_light.png" alt="logo" />
    <img class="img-dark" src="/static/img/logo_dark.png" alt="logo" />
  </div>
</template>

<script>
export default {
  name: "preloader",
  props: {
    show: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";
.page-preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background: var(--primary-bg-navy);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.4s ease-out;
  img {
    width: 60px;
    animation: anim 2s;
    animation-iteration-count: infinite;
  }
}

[data-theme="light"] body #app .content .home .page-preloader .img-light {
  display: none;
}

[data-theme="dark"] body #app .content .home .page-preloader .img-dark {
  display: none;
}

@keyframes anim {
  0% {
    transform: rotate(0deg) sacle(1);
  }
  25% {
    transform: rotate(180deg) scale(1.3);
  }
  50% {
    transform: rotate(360deg) scale(1.3);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.hideloader {
  opacity: 0;
  pointer-events: none;
}
</style>

const _projects = {
  featured: [
    {
      id: "1",
      title: "Ssport Plus",
      description:
        "A multi-page sports website. The purpose of this website is to live cast as well as playback  football, basketball, motor sports, fighting competitions, tennis matches and many more content in Full HD quality.",
      tools: [
        "HTML",
        "CSS3",
        "JavaScript",
        "Vuejs",
        "Vuex",
        "Videojs",
        "asp.net MVC",
      ],
      imgUrl: "/static/img/project01.png",
      websiteUrl: "https://app.ssportplus.com/",
      github: "",
    },
    {
      id: "2",
      title: "Akra Media",
      description:
        "A multi-page media website. The main purpose of this website is to provide media contents like News, Live Radio and Recorded audio services to the community.",
      tools: [
        "HTML",
        "CSS",
        "JavaScript",
        "Videojs",
        "asp.net core MVC",
        "MS SQL",
      ],
      imgUrl: "/static/img/project02.png",
      websiteUrl: "https://akra.media/",
      github: "",
    },
    {
      id: "3",
      title: "Where is the world?",
      description:
        "Is a single page application that displays all the countries using REST Countries V3 API, according to the provided designs. Users are able to: See all countries from the API on the homepage Search for a country, Filter countries by region, see more detailed information on a separate page,Toggle the color scheme between light and dark mode.",
      tools: [
        "HTML5",
        "CSS3",
        "SCSS",
        "Vuejs 3",
        "Vuex",
        "Axios",
        "TypeScript",
        "Cypress",
      ],
      imgUrl: "/static/img/countries.jpg",
      websiteUrl: "https://countries.uthmanmuhammad.com/",
      github: "https://github.com/uthmana/country-app",
    },

    {
      id: "4",
      title: "Naimextr Export & Import Service",
      description:
        "A multi-page website that gives information about export and import services of naimextr, Visitors can go through all the available product categories to choose a porduct, and can also send or call the company for more details.",
      tools: ["HTML", "CSS3", "JavaScript", "Reacjs", "Redux", "TypeScript"],
      imgUrl: "/static/img/project003.png",
      websiteUrl: "https://www.naimextr.com/",
      github: "",
    },

    {
      id: "5",
      title: "Isparta Gold Apart",
      description:
        "A multi-page Hotel website that provide accommodation and booking services in Isparta,  Visitors of the website get all the necessory information about the hotel and can book for a one or more days of stay.",
      tools: ["HTML", "CSS3", "JavaScript", "Vuejs", "Vuex", "Jquery"],
      imgUrl: "/static/img/project03.png",
      websiteUrl: "https://apart.uthmanmuhammad.com/",
      github: "",
    },
  ],
  noteworthy: [
    {
      id: "6",
      title: "MyReads",
      description:
        "MyReads project is a bookshelf app that allows you to select and categorize books you have read, are currently reading, or want to read.",
      tools: ["HTML", "CSS", "JavaScript", "React", "Fetch API"],
      imgUrl: "/static/img/project2.jpg",
      websiteUrl: "https://myreads.uthmanmuhammad.com/",
      github: "https://github.com/uthmana/MyReads",
    },

    {
      id: "7",
      title: "Teknik Pos",
      description:
        "Teknik Pos is a multi page restaurant pos application. The application allow customers to select foods from the menu  and place orders to their selected table with out the need of waiter.!",
      tools: ["HTML", "CSS", "JavaScript", "php", "jQuery"],
      imgUrl: "",
      websiteUrl: "http://pos.kucukyazilimlar.com/",
      github: "",
    },

    {
      id: "8",
      title: "Neighborhood Map React",
      description:
        "A single-page web application, built using the React framework, that displays a Google Map of an area and various points of interest. Users can search all included landmarks and, when selected, additional information about a landmark is presented from the FourSquare APIs.",
      tools: ["HTML", "CSS", "JavaScript", "React", "FourSquare APIs"],
      imgUrl: "/static/img/project3.jpg",
      websiteUrl: "https://uthmana.github.io/Neighborhood-Map-React/",
      github: "https://github.com/uthmana/Neighborhood-Map-React",
    },

    {
      id: "9",
      title: "Kesim SMS",
      description:
        "Kesim SMS is a single page application that shows registered users contact information based on Categories, The main purpose of the application is to send custom sms messages to single or multiple users. The SMS messages are sent by swiping left or clicking send button.",
      tools: ["HTML", "CSS", "ES6", "Nodejs", "Express"],
      imgUrl: "",
      websiteUrl: "https://kesim-sms.herokuapp.com/",
      github: "https://github.com/uthmana/kesim-sms",
    },

    {
      id: "10",
      title: "Time Line",
      description:
        "Time Line is widget that shows timestamps. The time indicator opens upon click to show package details",
      tools: ["HTML", "CSS", "JavaScript"],
      imgUrl: "",
      websiteUrl: "https://codepen.io/tk_1/full/JjNawMY",
      github: "",
    },

    {
      id: "11",
      title: "Cat Clicker",
      description:
        "Cat Clicker is multi player game app  that allow users to select cat and count the nummber of clicks in a given seconds.",
      tools: ["HTML", "CSS", "JavaScript"],
      imgUrl: "",
      websiteUrl: "https://codepen.io/tk_1/full/poyzBjM",
      github: "https://codepen.io/tk_1/pen/poyzBjM",
    },
    {
      id: "12",
      title: "Erlab Landing Page",
      description:
        "The Erlab Technolgy landing page is a full screen page with a fullscreen video and image background. The background video element changes accordingly when a user hover on the main element on the page",
      tools: ["HTML", "CSS/SCSS", "JavaScript", "jQuery"],
      imgUrl: "",
      websiteUrl: "https://codepen.io/tk_1/full/pogeOOV",
      github: "",
    },
  ],
};

export default _projects;

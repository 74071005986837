<template>
  <!-- {{ProjectData}} -->
  <div class="card-noteworthy" data-aos="fade-up">
    <div class="body-wrapper">
      <a :href="ProjectData.websiteUrl" target="_blank" rel="noopener">
        <div class="card-noteworthy--body">
          <h3>{{ ProjectData.title }}</h3>
          <p class="custom-scrollbar--card">{{ ProjectData.description }}</p>
        </div>
        <div class="card-noteworthy--footer">
          <span class="tools-box">
            <span v-for="(tool, index) in ProjectData.tools" :key="index">{{
              tool
            }}</span>
          </span>

          <span class="link-box">
            <a v-if="ProjectData.github" :href="ProjectData.github" target="_blank" rel="noopener"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-github"
              >
                <title>GitHub</title>
                <path
                  d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"
                ></path></svg
            ></a>
            <a v-if="ProjectData.websiteUrl" :href="ProjectData.websiteUrl" target="_blank" rel="noopener"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-external-link"
              >
                <title>External Link</title>
                <path
                  d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"
                ></path>
                <polyline points="15 3 21 3 21 9"></polyline>
                <line x1="10" y1="14" x2="21" y2="3"></line></svg
            ></a>
          </span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Noteworthy",
  props: {
    ProjectData: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";

.card-noteworthy {
  width: 300px;
  min-height: 360px;
  padding-bottom: 12px;
  transition: $transition;
  background: var(--secondary-bg-navy);
  border-radius: 0px 60px 0px 0px;
  margin: 12px;
  box-shadow: 5px 5px 25px 0 rgb(46 61 73 / 12%);
}

.card-noteworthy .body-wrapper {
  width: inherit;
  height: inherit;
  border-radius: 0px 60px 0px 0px;
  a {
    color: var(--primary-font-color);
    text-decoration: none;
  }
}

.card-noteworthy:hover {
  transform: translateY(-3px) !important;
}

.card-noteworthy--footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tools-box {
  margin-bottom: 6px;
}

.tools-box span {
  font-size: $fz-xs;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 0px;
  margin-right: 12px;
  color: var(--main-green-color);
  font-family: $font-mono;
}

.card-noteworthy--body h3 {
  color: var(--primary-font-color);
  position: relative;
  margin-top: 32px;
}
.card-noteworthy--body h6 {
  position: absolute;
  top: -48px;
  padding-left: 54px;
  color: var(--main-green-color);
}


.card-noteworthy--body{
  p{
    height: 150px;
    overflow-y: auto;
    padding-right: 12px;
  }
}



.card-noteworthy--body,
.card-noteworthy--footer {
  padding: 12px 12px 0px 20px;
  position: relative;
}
.link-box {
  padding-bottom: 12px;
}

.link-box a {
  padding: 4px;
  margin-right: 8px;
  display: inline-block;
  text-decoration: none;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
  color: inherit;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.link-box a svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: inherit;
  transition: $transition;
}

.link-box a svg:hover {
  color: var(--main-green-color);
}

@media screen and (max-width: 380px) {
  .card-noteworthy {
    width: 90%;
    margin: 20px auto;
  }
}

@media screen and (min-width: 991px) {
  .card-noteworthy {
    width: 300px;
    border-radius: 0px 60px 0px 0px;
  }
  .card-noteworthy .body-wrapper {
    padding: 12px 12px 0px 12px;
  }
}
</style>

<template>
  <div class="hero-wrapper">
    <div class="hero-items">
      <div
        class="hero-job"
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
        data-aos-delay="300"
        data-aos-offset="0"
      >
        <h1>Front-End Developer</h1>
      </div>
      <div class="hero-name" data-aos="fade-right" data-aos-duration="1600">
        <h2>Uthman Muhammad.</h2>
      </div>
      <div class="hero-title" data-aos="fade-right" data-aos-duration="1600">
        <h3>I build & Maintain websites.</h3>
      </div>

      <div class="hero-message" data-aos="fade-up" data-aos-duration="1600">
        <p>
          I love what I do and I do what my clients love. I work with great
          clients all over the world to create user-friendly, well-designed,
          responsive and accessible web projects, Currently, I'm focused on
          building media content related web applications at
          <a href="https://erlab.tech/" target="_blank"> Erlab Technology</a>.
        </p>
      </div>
      <div class="hero-action">
        <a class="mybtn-action" href="#contact">Get In Touch</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Heropage",
  data() {
    return {
      code: "<web/>;",
    };
  },
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";

.hero-wrapper {
  width: 90%;
  margin: auto;
  min-height: 500px;
  height: calc(100vh - 100px);
  background: var(--primary-bg-navy);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
}

.hero-hi {
  h1 {
    margin: 0px 0px 30px 4px;
    font-family: $font-mono;
    font-size: clamp($fz-sm, 5vw, $fz-md);
    color: var(--main-green-color);
  }
}

.hero-job {
  h1 {
    margin: 0px 0px 30px 4px;
    font-family: $font-mono;
    font-size: clamp($fz-sm, 5vw, $fz-md);
    font-weight: 00;
    background: var(--main-green-color);
    max-width: fit-content;
    color: var(--main-light_dark-color);
  }
}

.hero-name {
  margin-bottom: 0.8rem;
  h2 {
    margin: 0px;
    font-size: clamp(26px, 4vw, 80px);
    font-weight: 600;
    color: var(--tertiary-font-color);
    line-height: 1.1;
  }
}

.hero-title {
  margin-bottom: 1.5rem;
  h3 {
    margin: 0px;
    font-size: clamp(26px, 3.4vw, 80px);
    font-weight: 600;
    color: var(--secondary-font-color);
    line-height: 0.9;
    span {
      font-family: $font-mono;
      font-size: 1rem;
      color: var(--main-green-color);
    }
  }
}

.hero-message {
  margin-bottom: 2.5rem;
  p {
    max-width: 540px;
    a {
      color: var(--main-green-color);
      font-family: $font-mono;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.mybtn-action {
  padding: 0.8rem 0.8rem;
  border-radius: 5px;
  border: 2px solid var(--main-green-color);
  background: transparent;
  outline: none;
  color: var(--primary-font-color);
  font-weight: bold;
  font-size: $fz-lg;
  font-family: $font-mono;
  cursor: pointer;
  transition: color 0.5s;
  width: 100%;
  text-decoration: none;
  width: 140px;
  margin: 20px auto;
  transition: $transition;
}

.mybtn-action:hover {
  color: var(--main-green-color);
}

@media screen and (min-width: 991px) {
  .hero-wrapper {
    width: 90%;
    margin: 100px 0px 0px 0px;
  }

  .hero-name {
    margin-bottom: 0.8rem;
    h2 {
      font-size: clamp(40px, 8vw, 80px);
    }
  }

  .hero-hi {
    h1 {
      font-size: clamp($fz-sm, 5vw, $fz-md);
    }
  }

  .hero-title {
    margin-bottom: 1.5rem;
    h3 {
      font-size: clamp(40px, 3.5vw, 80px);
      span {
        font-size: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .hero-job {
    h1 {
      font-size: 16px;
      margin-top: 3rem;
    }
  }

  .mybtn-action {
    padding: 0.6rem 0.6rem;
    font-size: $fz-md;
  }
}
</style>

<template>
  <main id="app">
    <NavBar />
    <div class="content">
      <router-view />
    </div>
    <FooterBottom />
  </main>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import FooterBottom from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    NavBar,
    FooterBottom,
  },
};
</script>

<style lang="scss">
@import "./scss/_variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

:root {
  --primary-bg-navy: #0a192f;
  --secondary-bg-navy: #112240;
  --tertiary-bg-navy: #233554;

  --primary-font-color: #8892b0;
  --secondary-font-color: #a8b2d1;
  --tertiary-font-color: #ccd6f6;

  --main-green-color: #64ffda;
  --transparent-secondary-navy: #112240de;
  --main-light_dark-color: #000000;
  --main-dark_light-color: #e6f1ff;
  --main-white_navy-color: #0a192f;

  --main-navy_white-color: #112240;
}

[data-theme="light"] {
  --primary-bg-navy: #ffffff;
  --secondary-bg-navy: #e7e9eb;
  --tertiary-bg-navy: #e0e0e0;

  --primary-font-color: #4a4a49;
  --secondary-font-color: #757575;
  --tertiary-font-color: #1b1b1b;

  --main-green-color: #2a8e76;
  --transparent-secondary-navy: #e7e9eb99;
  --main-light_dark-color: #e6f1ff;
  --main-dark_light-color: #000000;
  --main-white_navy-color: #ffffff;
  --main-navy_white-color: #757575;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-bg-navy);
  color: var(--primary-font-color);
  font-family: "Inter", "Open Sans", sans-serif;
  transition: color ease-out 250ms;
  font-size: 15px;
}

.container {
  margin: 0px auto;
  max-width: 1000px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: var(--main-white_navy-color);
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: var(--main-white_navy-color);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--main-navy_white-color);
}


.custom-scrollbar--card::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 4px;
  background-color: var(--main-navy_white-color);
}

.custom-scrollbar--card::-webkit-scrollbar {
  width: 4px;
  background-color: var(--main-navy_white-color);
}

.custom-scrollbar--card::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--tertiary-bg-navy);
}



@media (max-width: 768px) {
  .container {
    padding: 0px;
  }
}
</style>

<template>
  <div class="skills-container" data-aos="fade-up">
    <h2>Skills</h2>

    <div class="skills-item-container">
      <ul>
        <li><h4>Languages</h4></li>
        <li><span>HTML5</span></li>
        <li><span>CSS3 & SCSS</span></li>
        <li><span>JavaScript (ES6)</span></li>
        <li><span>C#</span></li>
        <li><span>MS SQL</span></li>
      </ul>

      <ul>
        <li><h4>Frameworks & Libraries </h4></li>
        <li><span>Vuejs / Vuex</span></li>
        <li><span>Reactjs / Redux</span></li>
        <li><span>TypeScript</span></li>
        <li><span>Bootstrap</span></li>
        <li><span>Asp.net Core MVC</span></li>
      </ul>

      <ul>
        <li><h4>Tools</h4></li>
        <li><span> Git & Github</span></li>
        <li><span>Webpack</span></li>
        <li><span>Jasmine</span></li>
        <li><span>Postman</span></li>
        <li><span>Google Analytics</span></li>
        <li><span>GSC for SEO</span></li>
        <li><span>CLI</span></li>
      </ul>

      <ul>
        <li><h4>Design</h4></li>
        <li><span>Figma</span></li>
        <li><span>Photoshop</span></li>
        <li><span>Gimp</span></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Skills",
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";
.skills-container {
  width: 100%;
  margin-bottom: 8rem;
  h2 {
    color: var(--main-dark_light-color);
    padding-left: 24px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 18px;
      width: 100px;
      height: 1px;
      margin-left: 20px;
      background: var(--tertiary-bg-navy);
    }
  }
}

.skills-item-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  margin: 6px auto;

  ul {
    list-style-type: none;
    margin: 0;
    width: 50%;

    li {
      line-height: 1.5;
      h4 {
        margin-bottom: 12px;
        color: var(--main-green-color);
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .skills-item-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin: 12px auto;

    ul {
      list-style-type: none;
      margin: 0;
      width: 45%;
    }
  }
}

@media screen and (min-width: 991px) {
  .skills-container {
    width: 100%;
    margin-bottom: 5rem;
    h2 {
      padding-left: 0;
      &::after {
        width: 300px;
      }
    }
  }

  .skills-item-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 80%;
    margin: 12px auto;

    ul {
      list-style-type: none;
      margin: 0;
      width: 25%;
      li {
        line-height: 1.5;
        h4 {
          margin-bottom: 12px;
          color: var(--main-green-color);
        }
      }
    }
  }
}
</style>

export default function setTheme() {
  let _themeName = localStorage.getItem("theme");
  if (!_themeName) {
    _themeName = "dark";
  }

  if (_themeName == "light") {
    document.getElementById("slider").click();

    // document.getElementById("slider").checked = true;
  }

  localStorage.setItem("theme", _themeName);
  document.documentElement.setAttribute("data-theme", _themeName);
}

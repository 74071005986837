<template>
  <header id="navbar">
    <nav class="navbar-container">
      <a href="/#home" class="home-link">
        <div class="navbar-logo">
          <img class="img-dark" src="/static/img/logo_dark.png" alt="logo" />
          <img class="img-light" src="/static/img/logo_light.png" alt="logo" />
        </div>
      </a>
      <button
        type="button"
        class="navbar-toggle"
        aria-label="Open navigation menu"
      >
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <div class="navbar-menu">
        <ul class="navbar-links">
          <li class="navbar-item">
            <a class="navbar-link" href="/#about">About</a>
          </li>
          <li class="navbar-item">
            <a class="navbar-link" href="/#projects">Projects</a>
          </li>
          <li class="navbar-item">
            <a class="navbar-link" href="/#contact">Contact</a>
          </li>
          <li class="navbar-item">
            <a class="mybtn-resume" href="/static/img/myCV.pdf" target="_blank"
              >Resume</a
            >
          </li>

          <li class="navbar-item theme-switch">
            <label id="switch" class="switch">
              <input type="checkbox" @change="toggleTheme" id="slider" />
              <span class="slider round"></span>
            </label>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "NavBar",

  data() {
    return {
      navbar: null,
      navbarToggle: null,
      navbarMenu: null,
      navbarLinksContainer: null,
      navlink: null,
      new_scroll_position: 0,
      last_scroll_position: null,
    };
  },
  mounted() {
    this.navbar = document.getElementById("navbar");
    this.navbarToggle = document.querySelector(".navbar-toggle");
    this.navbarMenu = this.navbar.querySelector(".navbar-menu");
    this.navbarLinksContainer = this.navbar.querySelector(".navbar-links");
    this.navlink = document.querySelectorAll(".navbar-link");

    this.navbarToggle.addEventListener("click", () => {
      if (this.navbar.classList.contains("opened")) {
        this.closeMobileNavbar();
      } else {
        this.openMobileNavbar();
      }

      this.navlink.forEach((element) => {
        element.addEventListener("click", () => {
          if (this.navbar.classList.contains("opened")) {
            this.closeMobileNavbar();
          }
        });
      });

      this.navbarLinksContainer.addEventListener("click", (clickEvent) => {
        clickEvent.stopPropagation();
      });
      this.navbarMenu.addEventListener("click", this.closeMobileNavbar);
    });

    window.addEventListener("scroll", () => {
      this.showNavBarOnScroll();
    });
  },
  methods: {
    openMobileNavbar() {
      this.navbar.classList.add("opened");
      this.navbarMenu.classList.add("right", "sidebar");
      this.navbarToggle.setAttribute("aria-label", "Close navigation menu.");
    },
    closeMobileNavbar() {
      this.navbar.classList.remove("opened");
      this.navbarMenu.classList.remove("right", "sidebar");
      this.navbarToggle.setAttribute("aria-label", "Open navigation menu.");
    },
    showNavBarOnScroll() {
      this.last_scroll_position = window.scrollY;
      if (parseInt(window.scrollY) <= 2) {
        this.navbar.classList.remove("navbar-shadow");
      }

      // Scrolling up-down
      if (
        this.new_scroll_position < this.last_scroll_position &&
        this.last_scroll_position > 90
      ) {
        this.navbar.classList.add("slideUp", "navbar-shadow");
        this.navbar.classList.remove("slideDown");
      } else if (this.new_scroll_position > this.last_scroll_position) {
        this.navbar.classList.remove("slideUp");
        this.navbar.classList.add("slideDown");
      }

      this.new_scroll_position = this.last_scroll_position;
    },
    toggleTheme() {
    
      if (localStorage.getItem("theme") == "light") {
        this.setTheme("dark");
      } else {
        this.setTheme("light");
      }
        
    },
    setTheme(themeName) {
      localStorage.setItem("theme", themeName);
      document.documentElement.setAttribute("data-theme", themeName);
    },
  },
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";

#navbar {
  height: 60px;
  position: fixed;
  background-color: var(--primary-bg-navy);
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  padding: 8px 0px;
}

.navbar-shadow {
  box-shadow: 0 10px 30px -10px $navy-shadow !important;
}

[data-theme="light"]
  body
  #app
  #navbar
  .navbar-container
  .home-link
  .navbar-logo
  .img-light {
  display: none;
}

[data-theme="dark"]
  body
  #app
  #navbar
  .navbar-container
  .home-link
  .navbar-logo
  .img-dark {
  display: none;
}

/* Slide transitions */
.slideUp {
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
  transition: transform 0.5s ease-out;
}

.slideDown {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  transition: transform 0.5s ease-out;
  box-shadow: none;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  height: 100%;
  align-items: center;
}

.navbar-logo {
  img {
    width: 40px;
    padding-top: 4px;
  }
}

.home-link,
.navbar-link {
  color: var(--primary-font-color);
  transition: color 0.2s ease-in-out;
  text-decoration: none;
  display: flex;
  font-weight: 400;
  align-items: center;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.home-link:focus,
.home-link:hover {
  color: $navbar-text-color-focus;
}

.navbar-link {
  justify-content: center;
  width: 100%;
  padding: 0.4em 0.8em;
  border-bottom: 2px solid transparent;
  transition: border 0.5s;
}

.navbar-link:focus,
.navbar-link:hover {
  color: var(--main-green-color);
  border-bottom: 2px solid var(--main-green-color);
}

.navbar-toggle {
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.icon-bar {
  display: block;
  height: 2px;
  margin: 2.8px;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  background-color: var(--main-green-color);
}

.navbar-toggle .icon-bar:first-child,
.navbar-toggle .icon-bar:last-child {
  width: 25px;
}

.navbar-toggle .icon-bar:nth-child(2) {
  width: 20px;
  margin-right: -3px;
}

.navbar-toggle:focus .icon-bar,
.navbar-toggle:hover .icon-bar {
  background-color: var(--main-green-color);
}

#navbar.opened .navbar-toggle .icon-bar:first-child,
#navbar.opened .navbar-toggle .icon-bar:last-child {
  position: absolute;
  margin: 0;
}

#navbar.opened .navbar-toggle .icon-bar:first-child {
  transform: rotate(45deg);
}

#navbar.opened .navbar-toggle .icon-bar:nth-child(2) {
  opacity: 0;
}

#navbar.opened .navbar-toggle .icon-bar:last-child {
  transform: rotate(-45deg);
}

.navbar-menu {
  position: fixed;
  top: 60px;
  bottom: 0;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    left 0.2s ease-in-out, right 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.navbar-menu.sidebar,
.navbar-menu.sidebar.left {
  left: -1000px;
  right: 0;
}

.navbar-menu.sidebar.right {
  right: -1000px;
  left: 0;
}

#navbar.opened .navbar-menu {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  visibility: visible;
}

#navbar.opened .navbar-menu.sidebar.right {
  right: 0;
  height: 100vh;
}

.navbar-links {
  list-style-type: none;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
}

#navbar.opened .navbar-links {
  padding: 1em;
  max-height: none;
  background: var(--tertiary-bg-navy);
}

.sidebar .navbar-links {
  top: 0;
  bottom: 0;
}

.right.sidebar .navbar-links {
  right: 0;
  left: unset;
  box-shadow: -5px 20px 20px rgba(0, 0, 0, 0.3);
}

.navbar-item {
  margin: 0.4rem;
  width: 100%;
  text-align: center;
}


.theme-switch {
    text-align: center;
    height: calc(100% - 236px);
    display: flex;
    align-items: flex-end;
    justify-content: center;
}


.right.sidebar .navbar-links {
  margin: 0;
}

.mybtn-resume {
  padding: 0.45em 0.8em;
  border-radius: 5px;
  border: 2px solid var(--main-green-color);
  background: transparent;
  outline: none;
  color: var(--primary-font-color);
  font-weight: 400;
  font-size: $fz-md;
  font-family: $font-mono;
  cursor: pointer;
  transition: color 0.5s;
  width: 100%;
  text-decoration: none;
  display: block;
}

.mybtn-resume:hover {
  color: var(--main-green-color);
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 12px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccd6f6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 32px;
  left: 0px;
  bottom: 4px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 0px 15px #2020203d;
  background: white url("/static/img/night.png");
  background-repeat: no-repeat;
  background-position: center;
}

input:checked + .slider {
  background-color: #233554;
}

input:focus + .slider {
  box-shadow: 0 0 1px #233554;
}

input:checked + .slider:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px);
  background: white url("/static/img/sunny.png");
  background-repeat: no-repeat;
  background-position: center;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (min-width: 991px) {
  #navbar {
    height: 90px;
  }
  .navbar-toggle {
    display: none;
  }

  #navbar .navbar-menu,
  #navbar.opened .navbar-menu {
    visibility: visible;
    opacity: 1;
    position: static;
    display: block;
    height: 100%;
  }

  #navbar .navbar-links,
  #navbar.opened .navbar-links {
    margin: 0;
    padding: 0;
    box-shadow: none;
    position: static;
    flex-direction: row;
    list-style-type: none;
    max-height: max-content;
    width: 100%;
    height: 100%;
  }


.theme-switch {
    height: auto;
}

  #navbar .navbar-link:last-child {
    margin-right: 0;
  }
  #navbar.opened .navbar-links {
    background: transparent !important;
  }

  .navbar-container {
    width: 100%;
    padding: 0px 45px;
  }

  .navbar-logo {
    img {
      width: 48px;
    }
  }
}
</style>

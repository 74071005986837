<template>
  <div class="card-featured--container" data-aos="fade-up">
    <div class="card-featured" :class="setOrientation(ProjectData)">
      <div class="card-featured--header">
        <a class="imaglink-url" :href="ProjectData.websiteUrl" target="_blank" rel="noopener"
          ><img :src="ProjectData.imgUrl" alt="Project image"
        /></a>
      </div>
      <div class="body-wrapper">
        <div class="card-featured--body">
          <h6>Featured Project</h6>
          <h3>{{ ProjectData.title }}</h3>
          <p>{{ ProjectData.description }}</p>
        </div>
        <div class="card-featured--footer">
          <span class="tools-box">
            <span v-for="(tool, index) in ProjectData.tools" :key="index">{{
              tool
            }}</span>
          </span>

          <span class="link-box">
            <a v-if="ProjectData.websiteUrl" :href="ProjectData.websiteUrl"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-external-link"
              >
                <title>External Link</title>
                <path
                  d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"
                ></path>
                <polyline points="15 3 21 3 21 9"></polyline>
                <line x1="10" y1="14" x2="21" y2="3"></line></svg
            ></a>
            <a v-if="ProjectData.github" :href="ProjectData.github"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-github"
              >
                <title>GitHub</title>
                <path
                  d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"
                ></path></svg
            ></a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Featured",
  props: {
    ProjectData: {
      type: Object,
    },
  },
  methods: {
    setOrientation(obj) {
      if (parseInt(obj.id) % 2) {
        return "right";
      } else {
        return "left";
      }
    },
  },
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";

.card-featured--container {
  width: 100%;
}
.card-featured {
  width: 380px;
  padding-bottom: 12px;
  margin: 20px auto;
}

.card-featured--header {
  height: 215px;
}

.card-featured--header img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border: 2px solid var(--main-green-color);
  transition: border 0.3s ease-in;
  transition: $transition;
  opacity: 0.85;
}

.card-featured--header img:hover {
  border: 2px solid var(--main-green-color);
  opacity: 1;
}

.card-featured--footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tools-box {
  span {
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 15px;
    margin-right: 12px;
    font-weight: bold;
    color: var(--main-green-color) !important;
    font-family: $font-mono;
  }
}

.body-wrapper {
  background: var(--transparent-secondary-navy);
}

.card-featured--body h3 {
  color: var(--main-dark_light-color);
  position: relative;
  margin-top: 12px;
}

.card-featured--body h6 {
  position: absolute;
  top: -48px;
  padding-left: 54px;
  color: var(--main-green-color);
}

.card-featured.right .body-wrapper .card-featured--body h6::before,
.card-featured.left .body-wrapper .card-featured--body h6::before {
  content: "\2605";
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: var(--transparent-secondary-navy);
  top: -8px;
  left: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: var(--main-green-color);
}

.card-featured--body,
.card-featured--footer {
  padding: 12px 12px 0px 12px;
  position: relative;
}
.link-box {
  padding-bottom: 12px;
}

.link-box a {
  padding: 4px;
  margin-right: 8px;
  display: inline-block;
  text-decoration: none;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
  color: inherit;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.link-box a svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: inherit;
  transition: $transition;
}

.link-box a svg:hover {
  color: var(--main-green-color);
}

@media screen and (max-width: 380px) {
  .card-featured {
    width: 90%;
    margin: 20px auto;
  }
}

@media screen and (min-width: 991px) {
  .card-featured--container {
    margin-bottom: 2rem;
  }

  .card-featured {
    width: 991px;
    height: 470px;
    display: flex;
    position: relative;
  }
  .card-featured--header {
    width: 726px;
    height: 432px;
  }

  .card-featured--header img {
    border: 2px solid transparent;
    box-shadow: 5px 5px 25px 0 rgb(46 61 73 / 12%);
  }
  .card-featured--header img:hover {
    border: 2px solid var(--main-green-color);
  }
  .card-featured--header,
  .body-wrapper {
    position: absolute;
  }

  .card-featured--body p {
    margin: 0;
  }

  .card-featured .body-wrapper {
    padding: 0px 12px 0px 12px;
  }

  .card-featured.right .body-wrapper {
    right: 0;
    top: 200px;
    text-align: right;
    width: 472px;
    min-height: 246px;
    border-radius: 60px 0px 0px 0px;
  }
  .card-featured.right .body-wrapper .card-featured--body h6 {
    position: absolute;
    top: -48px;
    right: 0;
    padding-left: 0px;
    margin-right: 56px;
    padding-right: 22px;
  }
  .card-featured.right .body-wrapper .card-featured--body h6::before {
    position: absolute;
    top: -8px;
    left: 90px;
  }
  .card-featured.left .card-featured--header {
    right: 0;
  }
  .card-featured.left .body-wrapper {
    left: 0;
    top: 200px;
    text-align: left;
    width: 472px;
    min-height: 246px;
    border-radius: 0px 60px 0px 0px;
  }
  .card-featured.left .body-wrapper .card-featured--body h6 {
    position: absolute;
    top: -48px;
  }
  .card-featured.left .body-wrapper .card-featured--body h6::before {
    position: absolute;
    top: -8px;
    left: 0;
  }
}
</style>

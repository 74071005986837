<template>
  <footer class="footer-container">
    <span>© Copyright 2021, made by Uthman Muhammad</span>
  </footer>
</template>

<script>
export default {
  name: "FooterBottom",
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";
.footer-container {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--tertiary-bg-navy);
  font-size: $fz-sm;
  position: relative;
  z-index: 2;
  span {
    text-align: center;
  }
}
</style>
